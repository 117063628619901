.userDropdownWrap {
    @apply relative hidden m-auto;

    @screen laptop {
        @apply flex;
    }
}

.userDropdown {
    @apply shadow-lg bg-white w-full rounded-lg;

    :first-child a,
    :first-child button {
        @apply rounded-t-lg;
    }

    :last-child a,
    :last-child button {
        @apply rounded-b-lg;
    }
}

.userDropdownToggler {
    @apply ml-2 mr-0 my-auto w-10 h-10 rounded-full;

    &:focus {
        @apply outline-none;
    }

    &:hover {
        @apply bg-gray-muted text-gray-dark;
    }
}

.userDropdownTogglerBackend {
    @apply ml-2 mr-0 my-auto w-10 h-10 rounded-full;

    &:focus {
        @apply outline-none;
    }

    &:hover {
        @apply bg-gray-muted text-gray-dark;
    }
}

.icon {
    @apply my-auto flex-shrink-0 stroke-current mx-auto;
}

.iconBackend {
    @apply my-auto flex-shrink-0 stroke-current mx-auto;
}

.button {
    @apply items-center text-black font-semibold leading-tight;

    transition: 0.3s;
    font-size: 14px;

    &:active,
    &:focus {
        @apply outline-none;
    }

    &:disabled {
        @apply opacity-25 cursor-not-allowed;
    }
}

.rounded {
    border-radius: 5px;
}

.primary {
    @apply flex text-white bg-primary;

    &:hover {
        @apply text-primary-hover;
    }

    &:active {
        @apply text-primary-hover bg-primary-focus;
    }

    &:disabled {
        @apply opacity-100 cursor-not-allowed bg-gray-muted text-gray;
    }
}

.secondary {
    @apply flex text-primary bg-secondary;

    &:hover {
        @apply bg-secondary-hover;
    }

    &:active {
        @apply bg-secondary-focus;
    }
}

.black {
    @apply flex bg-black;

    &:hover {
        @apply bg-black;
    }
}

.blue-lighter {
    @apply flex bg-blue-lighter text-blue;

    &:hover {
        @apply bg-blue-semilight text-blue;
    }

    &:active,
    &:focus {
        @apply bg-blue-semilight text-blue;
    }
}

.red-background {
    @apply flex bg-red-background text-black;

    &:hover {
        @apply bg-red;
    }
}

.red-light {
    @apply bg-red-background text-red;

    &:hover {
        @apply bg-red-prettylight text-white;
    }
}

.red {
    @apply flex bg-red text-white;

    &:hover {
        @apply bg-red-dark;
    }
}

.red-dark {
    @apply flex bg-red-dark text-white;

    &:hover {
        @apply bg-red;
    }
}

.green {
    @apply flex bg-green text-white;

    &:hover {
        @apply bg-green-dark;
    }
}

.green-dark {
    @apply flex bg-green-dark text-white;

    &:hover {
        @apply bg-green-darker;
    }
}

.tableSelectableOptions {
    @apply flex bg-gray-muted text-black py-2 px-3;

    &:hover {
        @apply bg-gray-mutedhover;
    }
}

.transparent {
    @apply flex bg-transparent text-black px-0;

    &:hover {
        color: #5a5a5a;

        @apply bg-transparent shadow-none;
    }

    &:active,
    &:focus {
        color: #5a5a5a;

        @apply shadow-none;
    }
}

.outline {
    @apply flex bg-transparent border border-gray text-black;

    &:hover {
        @apply bg-transparent opacity-50;
    }
}

.dropdownLink {
    @apply flex bg-transparent normal-case rounded-none text-black;

    &:hover {
        @apply normal-case rounded-none bg-gray;
    }
}

.joker {
    @apply flex bg-green-background text-green-dark items-center;

    &:hover {
        @apply bg-green-semilight;
    }

    &:active,
    &:focus {
        @apply bg-green-prettylight;
    }

    &:disabled {
        @apply opacity-100 text-gray bg-white border border-gray-muted pointer-events-none;
    }
}

.invisible {
    @apply hidden;
}

.headerDropdown {
    padding: 20px;

    @apply flex bg-transparent rounded-none text-black normal-case font-normal;

    &:hover {
        @apply bg-gray-background shadow-none;
    }
}

.logoutLink {
    padding: 20px;

    @apply flex bg-transparent rounded-none text-red normal-case font-normal w-full text-left;

    &:hover {
        @apply bg-red-background shadow-none;
    }
}

.notification {
    @apply flex bg-transparent text-primary p-0 uppercase font-bold;

    &:hover {
        @apply text-primary-hover shadow-none;
    }
}

.gamePrimary {
    @apply flex text-white bg-primary;

    &:hover {
        @apply text-primary-hover;
    }

    &:active,
    &:focus {
        @apply text-primary-hover bg-primary-focus;
    }

    &:disabled {
        @apply opacity-25 cursor-not-allowed pointer-events-none;
    }
}

.outer {
    @apply w-full h-full bg-gray-muted rounded-full;
}

.inner {
    @apply h-full bg-primary rounded-full;

    transition: width 0.3s ease-in-out;
}

.indeterminate {
    transition: none;
    animation: 3s progress-indeterminate infinite alternate;
}

.primary {
    @apply bg-primary;
}

.red {
    @apply bg-red;
}

.yellow {
    @apply bg-yellow;
}

.greenLight {
    @apply bg-green-prettylight;
}

.green {
    @apply bg-green;
}

.red-background {
    @apply bg-red-background;
}

@keyframes progress-indeterminate {
    0% {
        width: 0;
        margin-right: auto;
        margin-left: 0;
    }

    1% {
        width: 0;
        margin-right: auto;
        margin-left: 0;
    }

    50% {
        width: 100%;
        margin-right: auto;
        margin-left: 0;
    }

    51% {
        width: 100%;
        margin-left: auto;
        margin-right: 0;
    }

    100% {
        width: 0;
        margin-left: auto;
        margin-right: 0;
    }
}

.gameEndWrapper {
    margin-bottom: 100px;

    @apply flex flex-wrap mx-auto;

    @screen tablet {
        margin-bottom: 200px;
    }
}

.correct {
    @apply text-green;
}

.wrong {
    @apply text-gray;
}

.correctBg {
    @apply bg-green-background text-green;
}

.wrongBg {
    @apply bg-white text-gray border border-gray-muted;
}

.notificationToggler {
    @apply flex my-auto ml-2 relative h-10 w-10 rounded-full;

    &:focus {
        @apply outline-none;
    }

    &:active {
        @apply outline-none;
    }

    &:hover {
        @apply bg-gray-muted text-gray-dark;
    }
}

.notificationTogglerBackend {
    @apply flex my-auto ml-4 relative h-10 w-10 rounded-full;

    &:focus {
        @apply outline-none;
    }

    &:active {
        @apply outline-none;
    }

    &:hover {
        @apply bg-gray-muted text-gray-dark;
    }
}

.icon {
    @apply my-auto mx-auto;
}

.iconBackend {
    @apply my-auto mx-auto;

    > path {
        stroke: white;
    }
}

.bell {
    width: 10px;
    height: 10px;
    top: 25px;

    @apply absolute bg-secondary-accent rounded-full;
}

.notificationListItem {
    @apply flex flex-row p-4 border-b text-14 border-gray-muted;
}

.past {
    @apply bg-gray-background;
}

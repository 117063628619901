.caret[data-placement="bottom"] {
    top: 0;
    left: 0;
    margin-top: -10px;

    &:before {
        border-top: none;

        border-left-color: transparent !important;
        border-right-color: transparent !important;
    }
}

.caret[data-placement="top"] {
    bottom: 0;
    left: 0;
    margin-bottom: -7px;

    &:before {
        border-bottom: none;

        border-left-color: transparent !important;
        border-right-color: transparent !important;
    }
}

.caret {
    display: block;
    width: 20px;
    height: 10px;

    @apply absolute;

    &:before {
        display: block;
        width: 100%;

        border-left: 10px solid;
        border-right: 10px solid;

        border-top: 10px solid;
        border-bottom: 10px solid;

        content: "";
    }
}

.overlay {
    @apply fixed inset-0 w-full h-full p-3 flex content-center overflow-scroll;

    background-color: #fafafa;

    @screen mobile {
        @apply p-8;
    }
}

.wrap {
    @apply flex w-full m-auto bg-white shadow-md;
    border-radius: 10px;

    max-width: 952px;
}

.content {
    @apply px-10 pt-10 pb-10;

    min-height: 548px;
}

.logowrap {
    @apply flex mb-10 justify-center;
}

.image {
    @apply min-h-full;

    border-radius: 10px 0 0 10px;

    background-image: url(../../images/loginbg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right bottom;
}

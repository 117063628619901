.header {
    height: 80px;

    @apply flex content-center bg-white;
}

.headerWrap {
    @apply z-20;
}

.headerBackend {
    height: 80px;

    @apply flex content-center bg-gray-background;
}

.headerLeft {
    @apply flex ml-0 mr-auto items-center content-center;
}

.headerRight {
    @apply hidden mr-0 ml-auto;

    @screen mobile {
        @apply flex;
    }
}

.brand {
    @apply text-lg mr-6 ml-0 my-auto font-bold;
}

.user {
    @apply my-auto flex items-center flex-wrap;

    min-height: 40px;
}

.navigation {
    @apply hidden ml-2 h-full;

    @screen mobile {
        @apply flex;
    }

    @screen tablet {
        @apply ml-4;
    }
}

.navigationIcon {
    @apply flex text-black stroke-current m-auto;

    @screen mobile {
        @apply hidden;
    }
}

.headerIcon {
    @apply mr-2 hidden;

    @screen tablet {
        @apply flex;
    }
}

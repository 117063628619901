.spinner {
    width: 40px;
    height: 40px;

    object-fit: scale-down;

    border: 3px solid transparent;
    border-radius: 50%;

    @apply border-gray-dark;

    animation: loading-spinner 1s;
    animation-iteration-count: infinite;
    transition: opacity 0.3s ease-in-out;

    display: inline-block;
    overflow: hidden;

    text-indent: 100%;
}

@keyframes loading-spinner {
    0% {
        transform: scale3d(0, 0, 0);
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        transform: scale3d(1, 1, 1);
        opacity: 0;
    }
}

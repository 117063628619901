.inviteInput {
    height: 56px;

    @apply text-black appearance-none w-full px-6 leading-tight border border-r-0 border-gray-muted;

    &::placeholder {
        @apply text-black opacity-25 text-14;
    }

    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &::-ms-input-placeholder {
        @apply text-black opacity-25;
    }

    &:focus {
        @apply outline-none;
    }

    &:hover {
        @apply bg-gray-slightly;
    }
}

.departmentSelect {
    height: 56px;
    border-radius: 0 !important;

    @apply text-14 text-gray w-full px-6 bg-white text-gray-dark leading-tight border border-l-0 border-gray-muted;

    &::placeholder {
        @apply text-black opacity-25 text-14;
    }

    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &::-ms-input-placeholder {
        @apply text-black opacity-25;
    }

    &:hover {
        @apply bg-gray-slightly cursor-pointer;
    }

    &:focus {
        @apply outline-none border-gray-muted;
    }
}

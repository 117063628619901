.link {
    @apply flex stroke-current items-center text-14 relative;
    @apply mx-3 text-gray;

    &:hover {
        @apply text-gray-dark stroke-current;
    }

    &:after {
        content: "";

        @apply
            opacity-0
            absolute
            left-0
            top-0
            bg-primary
            rounded-b-lg
            w-full
            h-1;
    }

    @screen tablet {
        @apply mx-4;
    }
}

.active {
    @apply text-primary border-primary;

    &:after {
        @apply opacity-100;
    }
}

.primary {
    @apply mx-3 text-primary;

    &:hover {
        @apply text-primary stroke-current;
    }

    &.active {
        @apply text-primary;
    }

    @screen tablet {
        @apply mx-4;
    }
}

.overlay {
    @apply flex fixed min-h-full w-1/2 justify-center;

    background-image: url(../../../../images/loginbg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left top;
}

.wrapper {
    @apply flex self-center flex-col z-10 p-10 bg-white shadow-xl;
}

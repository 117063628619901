.dotNavigationWrap {
    @apply relative flex m-auto;

    @screen laptop {
        @apply hidden;
    }
}

.dotNavigation {
    @apply shadow-lg bg-white w-full rounded-lg;

    :first-child a,
    :first-child button {
        @apply rounded-t-lg;
    }

    :last-child a,
    :last-child button {
        @apply rounded-b-lg;
    }
}

.dotNavigationToggler {
    @apply ml-2 mr-0 my-auto h-10 w-10 rounded-full;

    &:focus {
        @apply outline-none;
    }

    &:hover {
        @apply bg-gray-muted text-gray-dark;
    }
}

.dotNavigationTogglerBackend {
    @apply ml-2 mr-0 my-auto h-10 w-10 rounded-full;

    &:focus {
        @apply outline-none;
    }

    &:hover {
        @apply bg-gray-muted text-gray-dark;
    }
}

.icon {
    @apply text-black my-auto flex-shrink-0 stroke-current mx-auto;
}

.iconBackend {
    @apply text-black my-auto flex-shrink-0 stroke-current mx-auto;
}

.backdrop {
    @apply flex justify-center items-center w-full h-full z-20;

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-color: rgba(0, 0, 0, 0.5);
}

.modal {
    @apply flex flex-col m-auto shadow-lg w-full h-screen bg-white;

    @screen mobile {
        @apply h-auto w-1/2 rounded-lg;
    }

    @screen tablet {
        @apply w-1/3;
    }
}

.closeModalButton {
    @apply ml-auto;

    &:focus,
    &:active {
        @apply outline-none;
    }
}

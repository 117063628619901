.burgerMenuWrapper {
    height: 80px;
    padding: 1.75rem 1.5rem;

    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);

    @apply flex items-center justify-between;
}

.burgerMenuWrapperBackend {
    min-height: 80px;

    @apply flex p-6 items-center justify-between bg-gray-background;
}

.burgerMenuToggler {
    @apply p-3 pr-0 mr-0 my-auto;

    &:focus {
        @apply outline-none;
    }

    @screen mobile {
        @apply hidden;
    }
}

.burgerMenuTogglerBackend {
    @apply p-3 pr-0 mr-0 my-auto;

    &:focus {
        @apply outline-none;
    }

    @screen mobile {
        @apply hidden;
    }
}

.icon {
    @apply my-auto text-black flex-shrink-0 stroke-current;
}

.iconBackend {
    @apply my-auto text-black flex-shrink-0 stroke-current;
}

.burgerMenuLink {
    @apply flex w-full p-4 text-14 text-center rounded-xl items-center justify-center mt-3;
    @apply mx-6 text-gray border border-gray-muted;

    &:hover {
        @apply text-primary stroke-current;
    }

    &.active {
        @apply text-primary bg-secondary border-secondary;
    }

}

.mobileLogoutButton {
    @apply flex w-full p-4 text-14 text-red mt-3 mx-6 justify-center border border-red-background rounded-xl;
}

.popup {
    position: fixed;

    bottom: 1em;

    width: 92%;
    left: 4%;

    @apply p-2 text-white bg-white shadow-lg rounded-xl z-20;

    @screen mobile {
        width: 200px;

        left: calc(50% - 100px);

        @apply p-4;
    }
}

.popupWide {
    @apply flex items-center p-2 mx-auto;

    left: 16px;
    width: calc(100% - 32px);

    @screen mobile {
        @apply p-6;

        width: 588px;

        left: calc(50% - 300px);
    }
}

.saveIcon {
    width: 4rem;
    height: 100%;

    circle {
        fill: #00b454;
    }
}

.failIcon {
    width: 4rem;
    height: 100%;

    circle {
        fill: #a22603;
    }
}

.green {
    @apply bg-green;
}

.red {
    @apply bg-red;
}

.question {
    font-size: 32px;

    @apply w-full mx-auto flex-shrink-0 my-12 font-sans;

    @screen mobile {
        @apply text-center;
    }

    @screen desktop {
        @apply w-3/4 my-20;
    }
}

.answerWrapper {
    margin-bottom: 100px;

    @apply flex flex-wrap w-full;

    @screen mobile {
        margin-bottom: 190px;
    }

    @screen desktop {
        @apply w-3/4 mx-auto;
    }
}

.answerNumberWrapper {
    @apply flex;
}

.answerNumber {
    @apply flex bg-primary text-white rounded-full h-8 w-8 justify-center items-center;
}

.answer {
    @apply w-full flex p-8 text-lg bg-white rounded-xl shadow flex-col;

    @screen mobile {
        @apply flex-row;
    }
    &:not(.answerCorrect):not(.answerWrong):not(.answerJoker) {
        .answerNumber {
            @apply bg-primary-light;
        }

        &:not(.answerSelected) {
            &:not(.answerJoker):hover {
                @apply bg-gray-background;
            }

            .answerNumber {
                @apply bg-primary;
            }
        }
    }
}

.answerSelected {
    &:not(.answerCorrect):not(.answerWrong) {
        @apply border-transparent bg-primary text-white;
    }

    &.answerCorrect {
        @apply bg-green text-white;

        .answerNumber {
            @apply bg-green-dark;
        }
    }

    &.answerWrong {
        @apply bg-red text-white;

        .answerNumber {
            @apply bg-red-dark;
        }
    }
}

.answerCorrect:not(.answerSelected) {
    @apply border-0 text-green bg-green-background;

    .answerNumber {
        @apply bg-green;
    }
}

.answerWrong:not(.answerSelected) {
    @apply border border-gray-muted shadow-none text-gray bg-white;

    .answerNumber {
        @apply bg-gray-muted text-gray;
    }
}

.answerJoker {
    @apply shadow-none border border-gray-muted text-gray pointer-events-none;

    .answerNumber {
        @apply bg-gray-muted text-gray;
    }
}

.button {
    @apply my-auto ml-auto mr-0 my-auto rounded-lg bg-gray;

    &:hover {
        @apply bg-gray;
    }

    &:focus {
        @apply outline-none;
    }

    &:disabled {
        @apply opacity-25 cursor-not-allowed;
    }
}

.questionWrapper {
    @apply flex flex-wrap justify-center;
}

.validationWrap {
    height: 80px;
    bottom: 0;

    @apply fixed flex flex-wrap flex-shrink-0 w-full mx-auto mt-20 mb-0 items-center bg-white border-t border-gray-muted;

    @screen mobile {
        height: 180px;

        @apply py-8;
    }
}

.validationCorrect,
.validationWrong {
    @apply border-transparent;
}

.validationCorrect {
    @apply bg-green-background;

    .title {
        @apply text-green;
    }

    .answerNumber {
        @apply bg-green opacity-50;
    }

    .button {
        @apply bg-green text-white;
    }
}

.validationWrong {
    @apply bg-red-background;

    .title {
        @apply text-red;
    }

    .answerNumber {
        @apply bg-red opacity-100;
    }

    .button {
        @apply bg-red text-white;
    }
}

.tooltipWrapper {
    @apply flex select-none text-gray font-sans items-center;

    .tooltip {
        visibility: hidden;
    }

    &:hover .tooltip {
        visibility: visible;
    }
}

.tooltip {
    @apply bg-white text-xl p-6 rounded shadow;
}
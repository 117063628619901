.logo {
    width: 175px;
    height: 41px;

    @apply mb-12;
}

.input {
    height: 56px;

    @apply text-black appearance-none w-full px-6 leading-tight border border-gray-muted;

    &::placeholder {
        @apply text-black opacity-25 text-14;
    }

    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &::-ms-input-placeholder {
        @apply text-black opacity-25;
    }

    &:focus {
        @apply outline-none;
    }

    &:hover {
        @apply bg-gray-slightly;
    }
}

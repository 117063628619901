@tailwind base;

@tailwind components;

@tailwind utilities;

body {
    @apply bg-white;
}

.container {
    @apply px-6;

    @screen mobile {
        @apply px-8;
        max-width: 1440px;
    }

    @screen tablet {
        @apply overflow-x-hidden;
    }
}

.header-container {
    @apply px-6;

    @screen mobile {
        @apply px-8;
        max-width: 1440px;
    }
}

.caret-up:before {
    content: "";

    border-left: 10px solid;
    border-right: 10px solid;
    border-bottom: 10px solid;

    border-left-color: transparent !important;
    border-right-color: transparent !important;

    position: absolute;
    top: -10px;
    left: calc((100% - 10px) / 2);

    @apply border-gray;
}

button:focus {
    outline: none;
}

label {
    @apply text-xs block font-bold w-full py-2;
}

.label-uppercase > label {
    @apply uppercase;
}

.custom-checkbox label {
    @apply text-base font-semibold;
}

.input {
    height: 56px;

    @apply text-black appearance-none rounded w-full px-6 leading-tight border border-gray-muted;

    &::placeholder {
        @apply text-black opacity-25 text-14;
    }

    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &::-ms-input-placeholder {
        @apply text-black opacity-25;
    }

    &:focus {
        @apply outline-none border-gray;
    }

    &:hover {
        @apply bg-gray-slightly;
    }
}

select {
    height: 56px;

    @apply text-gray rounded w-full px-6 bg-white text-gray-dark leading-tight custom-chevron border border-gray-muted;

    &::placeholder {
        @apply text-black opacity-25 text-14;
    }

    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &::-ms-input-placeholder {
        @apply text-black opacity-25;
    }

    &:hover {
        @apply bg-gray-slightly cursor-pointer;
    }

    &:focus {
        @apply outline-none border-gray;
    }
}

.custom-chevron {
    padding: 0 1rem;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background: url("./svg/chevron-down-gray-light.svg") calc(100% - 15px) 23px / 14px
        no-repeat;
}

h1 {
    @apply text-3xl font-light;
}

textarea {
    @apply bg-white rounded-lg p-4;
}

.select-inline label {
    @apply p-0;
}

.table-striped tr:nth-child(odd) {
    background-color: #fafafa;
}

.input-error-message {
    @apply text-red text-xs;
}

.input + span {
    @apply text-red text-xs;
}

.tooltipWrapper {
    @apply flex select-none text-gray font-sans items-center;

    .tooltip {
        visibility: hidden;
    }

    &:hover .tooltip {
        visibility: visible;
    }
}

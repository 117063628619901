.jokerWrapper {
    @apply w-full flex flex-wrap;

    @screen mobile {
        @apply flex-no-wrap w-auto;
    }
}

.jokerFadeout {
    -webkit-animation: fadeOut 0.3s;
    animation: fadeOut 0.3s;
    animation-fill-mode: forwards;
}

@-webkit-keyframes fadeOut {
    0% { opacity: 1; }
    10% { opacity: 0; }
    100% { opacity: 0; width: 0; height: 0; margin: 0; padding: 0; }
}

@keyframes fadeOut {
    0% { opacity: 1; }
    10% { opacity: 0; }
    100% { opacity: 0; width: 0; height: 0; margin: 0; padding: 0; }
}

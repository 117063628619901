.search {
    height: 40px;
    width: 230px;
    font-size: 12px;

    @apply my-auto pl-8 pr-4 rounded-full outline-none bg-gray-background;

    &:focus {
        @apply bg-white border-2 border-gray-background;
    }

    &::placeholder {
        font-size: 12px;

        @apply text-gray;
    }

    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &::-ms-input-placeholder {
        @apply text-gray;
    }

    @screen mobile {
        width: 170px;
    }
}

.lenseSign {
    position: absolute;
    left: 0px;
    top: 15px;
    width: 13px;
    height: 13px;

    @apply ml-4;

    @screen mobile {
        top: 34px;
    }
}

.searchBackend {
    height: 40px;
    width: 170px;
    font-size: 12px;

    @apply text-gray;

    @apply my-auto pl-8 pr-4 rounded-full outline-none bg-gray-muted;

    &:focus {
        @apply bg-white border-2 border-gray-background;
    }

    &::placeholder {
        font-size: 12px;

        @apply text-gray;
    }

    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &::-ms-input-placeholder {
        @apply text-gray;
    }
}

.lenseSignBackend {
    position: absolute;
    left: 0px;
    top: 15px;
    width: 13px;
    height: 13px;

    @apply ml-4;

    > path {
        @apply text-gray stroke-current;
    }

    @screen mobile {
        top: 34px;
    }
}

.searchLoading {
    @apply flex py-12 text-center w-screen justify-center items-center;

    height: calc(100vh - 5rem);

    @screen mobile {
        @apply w-64;

        height: auto;
        max-height: 20vh;
    }

}

.searchResults {
    @apply mt-20 z-10 w-screen overflow-y-scroll bg-white;

    height: calc(100vh - 5rem);

    @screen mobile {
        @apply mt-3 w-64 overflow-y-visible bg-transparent;

        height: auto;
        max-height: 20vh;
    }
}

.searchResult {
    @apply flex p-4 pr-2;

    &:hover {
        @apply bg-gray-background cursor-pointer;
    }
}

.spinner {
    width: 40px;
    height: 40px;

    object-fit: scale-down;

    border: 3px solid transparent;
    border-radius: 50%;

    @apply border-gray-dark;

    animation: loading-spinner 1s;
    animation-iteration-count: infinite;
    transition: opacity 0.3s ease-in-out;

    display: inline-block;
    overflow: hidden;

    text-indent: 100%;
}

@keyframes loading-spinner {
    0% {
        transform: scale3d(0, 0, 0);
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        transform: scale3d(1, 1, 1);
        opacity: 0;
    }
}

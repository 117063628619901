.wrapper {
    display: inline-block;
    position: relative;
}

.label {
    font-size: 16px;

    display: flex;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @apply py-0;

    &:before {
        content: " ";

        display: inline-block;

        height: 1.5rem;
        width: 1.5rem;

        background-image: url(../../svg/checkbox_inactive.svg);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    &:not(:empty):before {
        @apply mr-2;
    }

    &:hover {
        cursor: pointer;
    }
}

.input {
    position: absolute;
    visibility: hidden;

    &:checked + .label:before {
        background-image: url(../../svg/checkbox_active.svg);
    }
}

.dropdown {
    @apply bg-white shadow-lg rounded-lg z-10;

    @screen mobile {
        @apply;

        :first-child a,
        :first-child button {
            @apply rounded-t-lg;
        }

        :last-child a,
        :last-child button {
            @apply rounded-b-lg;
        }
    }
}
